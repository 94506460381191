import React from "react";
import { StaticQuery, graphql } from "gatsby"
import { AssetQuery, Image, IImageProps } from "./image";

export default class ImageWrapper extends React.Component<IImageProps>{
    render() {
        return <StaticQuery
            query={graphql`
                query {
                    assets: allFile(filter: {sourceInstanceName:{eq: "assets"}}) {
                        edges {
                            node {
                                absolutePath
                                childImageSharp {
                                    id
                                    fluid(maxHeight: 500){
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data: AssetQuery) => (
                <Image
                    background={this.props.background}
                    alt={this.props.alt}
                    className={this.props.className}
                    src={this.props.src}
                    assets={data.assets}
                />
            )}
        />
    }
}