import Img from "gatsby-image"
import React, { CSSProperties } from "react";

export interface AssetQuery {
    assets: { edges: Array<GraphEdge<Asset>> }
}

type IImageFullProps = IImageProps & AssetQuery

interface BackgroundProps {
    backgroundX: "center" | "left" | "right"
    backgroundY: "bottom" | "center" | "top"
    zIndex?: number
}

export interface IImageProps {
    background?: BackgroundProps
    src: string
    alt: string
    className?: string
}

const getStyleFor = (background?: BackgroundProps) => {
    if (!background) {
        return {}
    }
    return {
        left: 0,
        top: 0,
        objectPosition: `${background.backgroundY} ${background.backgroundX}`,
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: background.zIndex || 0,
      }
}

export class Image extends React.Component<IImageFullProps> {
    render() {
        const {className, src, alt, background} = this.props
        const fallback = this.props.background ? 
            <div style={{backgroundImage: this.props.src}}></div> : 
            <img className={className} src={src} alt={alt}/>

        const style = getStyleFor(background)

        return <BaseImage 
            image={this.props}
            fallback={fallback}
            style={style}
        />
    }
}

interface BaseImageProps {
    style: CSSProperties | {}
    fallback: JSX.Element
    image: IImageFullProps
}

class BaseImage extends React.Component<BaseImageProps>{
    render() {
        const {className, src, alt, assets} = this.props.image
        const normSrc = src.toLowerCase()
        const imgAsset = assets.edges.filter((a) => {
            return a.node.absolutePath.toLowerCase().endsWith(normSrc)
        })
        if (imgAsset.length > 0) {
            return <Img className={className}
                fluid={imgAsset[0].node.childImageSharp.fluid}
                critical={true}
                alt={alt}
                style={{...this.props.style}}
            />
        }
        console.error(`Failed to find asset which ends with: ${normSrc}. Trying fallback instead.`)
        console.error(`List of all assets checked:`)
        console.table(assets.edges.map((i) => i.node.absolutePath))
        return this.props.fallback
    }
}